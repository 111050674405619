import { Box, Button, Center, FormLabel, Text } from '@chakra-ui/react';
import Input from 'components/form/Input';
import routes from 'constants/routes';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { selectUser, setUser } from 'store/slices/authSlice';
import { match_email, required } from 'utils/validation';
import { useLazyUsersRetrieveQuery, useUserLoginMutation } from 'store/services/users';
import Error from 'components/form/Error';

const SignInPage = () => {
  const user = useSelector(selectUser);
  const [params] = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [getMe] = useLazyUsersRetrieveQuery();

  const [login] = useUserLoginMutation();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [touched, setTouched] = useState({});

  const returnUrl = params && params.get('redirect');

  if (user) {
    return <Navigate to={returnUrl || routes.home} />;
  }

  const onSubmit = async e => {
    e.preventDefault();
    setErrors({});
    setIsSubmiting(true);
    try {
      await login({ email: email.toLowerCase(), password }).unwrap();
      const { data: user } = await getMe();
      dispatch(setUser(user));
    } catch (error) {
      if (error.status === 400) {
        const errors = Object.keys(error.data).reduce((acc, key) => {
          acc[key] = error.data[key][0];
          return acc;
        }, {});
        setErrors(errors);
      }
    }
    setIsSubmiting(false);
  };

  return (
    <Box>
      <Text size="title">Log In</Text>

      <Box mt="30px" as="form" onSubmit={onSubmit}>
        <Box>
          <FormLabel>Email</FormLabel>
          <Input
            name="email"
            type="email"
            placeholder="Enter a valid email address"
            value={email}
            onChange={e => {
              setTouched({ ...touched, email: true });
              setEmail(e.target.value.trim());
            }}
          />

          {touched.email && match_email(email) && <Error mt="10px">Please provide a valid email address</Error>}
        </Box>
        <Box mt="20px">
          <FormLabel>Password</FormLabel>
          <Input
            name="password"
            type="password"
            placeholder="Create a password"
            value={password}
            onChange={e => {
              setPassword(e.target.value.trim());
              setTouched({ ...touched, password: true });
            }}
          />
          {touched.password && required(password) && <Error mt="10px">It’s required</Error>}
        </Box>

        <Center mt="30px">
          <Button type="submit" isDisabled={isSubmiting || match_email(email) || password.length === 0}>
            Log In
          </Button>
        </Center>

        <Box mt="40px" color="var(--chakra-colors-white80)" textAlign="center" fontSize="16px" fontWeight="700">
          Don’t have an account? <Link to={routes.signup}>Sign Up</Link>
        </Box>
        <Box mt="15px" textAlign="center" fontSize="16px" fontWeight="700">
          <Link to={routes.resetPassword}>Forgot password?</Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SignInPage;
