import { useEffect, useState } from 'react';

import { Box, SimpleGrid, Flex } from '@chakra-ui/react';

const PostedState = ({
  userAnswers,
  onSelect,
  state: {
    activeQuestion: { answers },
    is_in_the_contest,
  },
}) => {
  const [selected, setSelected] = useState(undefined);
  useEffect(() => {
    setSelected(undefined);
  }, [userAnswers]);

  return (
    <Box width="100%" mt="30px">
      <SimpleGrid columns={answers.length <= 2 ? 1 : 2} spacing="10px" rowGap="10px">
        {answers.map(answer => {
          const hasAnswer =
            selected !== undefined
              ? selected === answer.id
              : userAnswers.findIndex(ua => ua.answer === answer.id) !== -1;
          return (
            <Flex
              key={answer.id}
              h="52px"
              alignItems="center"
              justifyContent="center"
              bg={hasAnswer ? '#FFFFFF66' : is_in_the_contest ? '#FFFFFF1A' : '#FFFFFF0D'}
              borderRadius="32px"
              pos="relative"
              fontSize="14px"
              fontWeight="700"
              border={hasAnswer ? '2px solid #FFFFFF66' : '2px solid transparent'}
              onClick={e => {
                if (!is_in_the_contest) return;
                e.preventDefault();
                onSelect(answer.id);
                setSelected(answer.id);
              }}
            >
              {answer.answer}
            </Flex>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default PostedState;
