import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  gameState: undefined,
  selectedAnswer: undefined,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    resetGame(state) {
      state.gameState = undefined;
    },
    setGameState(state, { payload }) {
      state.gameState = payload;
    },

    setSelectedAnswer(state, { payload }) {
      state.selectedAnswer = payload;
    },
  },
});

export default persistReducer(
  {
    key: 'rtk:game',
    storage,
    whitelist: [],
  },
  gameSlice.reducer,
);

export const { resetGame, setGameState, setSelectedAnswer } = gameSlice.actions;

export const selectGameState = state => state.game.gameState;
export const selectSelectedAnswer = state => state.game.selectedAnswer;
