import { createAction, createSlice } from '@reduxjs/toolkit';

// todo add persistReducer when changing to standard auth
const initialState = {
  user: null,
  userLives: null,
  isAuthenticated: false,
  hasSubscription: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated(state, { payload = true }) {
      state.isAuthenticated = payload;
    },
    setUser(state, { payload }) {
      state.user = payload;
    },
    setHasSubscription(state, { payload = false }) {
      state.hasSubscription = payload;
    },
    setUserLives(state, { payload }) {
      state.userLives = payload;
    },
  },
});

export default authSlice.reducer;

export const { setIsAuthenticated, setUser, setHasSubscription, setUserLives } = authSlice.actions;

export const selectUser = state => state.auth.user;
export const selectUserLives = state => state.auth.userLives;
export const selectIsAuthenticated = state => state.auth.isAuthenticated;
export const selectHasSubscription = state => state.auth.hasSubscription;
