import { Box, Button, Center, FormLabel, Text } from '@chakra-ui/react';
import Input from 'components/form/Input';
import routes from 'constants/routes';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { selectUser } from 'store/slices/authSlice';
import { required } from 'utils/validation';
import { useResetPasswordConfirmMutation } from 'store/services/users';
import Error from 'components/form/Error';

const ResetPasswordConfirmPage = () => {
  const user = useSelector(selectUser);
  const [params] = useSearchParams();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [sent, setSent] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [touched, setTouched] = useState({});
  const { token, uid } = useParams();
  const [resetPasswordConfirm] = useResetPasswordConfirmMutation();
  const navigate = useNavigate();

  const returnUrl = params && params.get('redirect');

  if (user) {
    return <Navigate to={returnUrl || routes.game} />;
  }

  const onSubmit = async e => {
    e.preventDefault();
    setErrors({});
    setIsSubmiting(true);
    try {
      const body = { new_password: password, re_new_password: password, uid, token };
      await resetPasswordConfirm(body).unwrap();
      setSent(true);
    } catch (error) {
      if (error.status === 401 || error.status === 400) {
        if (error.data.token || error.data.uid) {
          navigate('/', { replace: true });
          return;
        }
        const errors = Object.keys(error.data).reduce((acc, key) => {
          acc[key] = Array.isArray(error.data) ? error.data[key][0] : error.data[key];
          return acc;
        }, {});

        setErrors(errors);
      }
    }
  };

  if (sent) {
    return (
      <Box mt="150px">
        <Text size="title1">Your password was successfully updated</Text>
        <Text color="var(--chakra-colors-white80)" size="body" textAlign="center" mt="20px" lineHeight="22px">
          You can now use your new password to log in to your account
        </Text>
        <Center mt="30px">
          <Button as={Link} to={routes.signin}>
            Log in
          </Button>
        </Center>
      </Box>
    );
  }

  return (
    <Box>
      <Text size="title">Set new password</Text>

      <Box mt="30px" as="form" onSubmit={onSubmit}>
        <Box>
          <FormLabel>New password</FormLabel>
          <Input
            name="password"
            type="password"
            value={password}
            onChange={e => {
              setTouched({ ...touched, password: true });
              setPassword(e.target.value.trim());
            }}
          />

          {touched.password && required(password) && <Error mt="10px">It’s required</Error>}
        </Box>
        <Box mt="20px">
          <FormLabel>Repeat new password</FormLabel>
          <Input
            name="repeat-password"
            type="password"
            value={repeatPassword}
            onChange={e => {
              setTouched({ ...touched, repeatPassword: true });
              setRepeatPassword(e.target.value.trim());
            }}
          />

          {touched.repeatPassword && password !== repeatPassword && <Error mt="10px">Passwords don’t match</Error>}
        </Box>
        <Center mt="30px">
          <Button type="submit" isDisabled={isSubmiting || required(password) || password !== repeatPassword}>
            Confirm
          </Button>
        </Center>
      </Box>
    </Box>
  );
};

export default ResetPasswordConfirmPage;
