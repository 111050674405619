import { Flex, Text } from '@chakra-ui/react';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';
import { ReactComponent as HeartIcon } from 'icons/heart.svg';
import { ReactComponent as SwordIcon } from 'icons/sword.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from 'constants/routes';
import Menu from './Menu';
import { selectUser, selectUserLives } from 'store/slices/authSlice';
import { selectGameState } from 'store/slices/gameSlice';

function formatNumber(num) {
  if (num < 1000) {
    return num.toString();
  } else {
    let formattedNum = (num / 1000).toFixed(1);
    if (formattedNum.endsWith('.0')) {
      formattedNum = formattedNum.slice(0, -2);
    }
    return formattedNum + 'k';
  }
}

const Header = ({ layout }) => {
  const gameState = useSelector(selectGameState);
  const user = useSelector(selectUser);
  const userLives = useSelector(selectUserLives);
  const isApp = layout === 'ios_native';

  return (
    <Flex
      pos="relative"
      px="15px"
      h={isApp ? 'min-content' : '28px'}
      justifyContent={isApp ? 'center' : 'space-between'}
      zIndex="200"
      flexDirection="row"
    >
      <Flex
        justifyContent={isApp ? 'center' : 'flex-start'}
        alignItems="center"
        h="100%"
        gap="5px"
        flexShrink="0"
        py={isApp ? '5px' : '0'}
      >
        {!isApp && <Menu />}
        <Link to={routes.home}>
          <LogoIcon />
        </Link>
      </Flex>
      <Flex
        alignItems="center"
        h="100%"
        justifyContent="flex-end"
        flexBasis="100%"
        style={
          isApp
            ? {
                position: 'absolute',
                right: '15px',
                top: '0px',
              }
            : {}
        }
      >
        {gameState && userLives !== null && (
          <Flex alignItems="center" gap="10px">
            {user && (
              <Flex gap="2px" alignItems="center">
                <HeartIcon width="22px" height="22px" />
                <Text fontWeight="700" fontSize="16px">
                  {userLives}
                </Text>
              </Flex>
            )}
            <Flex gap="2px" alignItems="center">
              <SwordIcon />
              <Text fontWeight="700" fontSize="16px">
                {formatNumber(gameState.count_active_users)}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
