import { configureStore } from '@reduxjs/toolkit';
import { api } from './services/api';
import auth from './slices/authSlice';
import game from './slices/gameSlice';

// axiosInstance.interceptors.response.use(
//   response => {
//     return response;
//   },
//   async error => {
//     const originalRequest = error.config;

//     if (originalRequest.url === 'auth/jwt/refresh') {
//       store.dispatch(logout());
//       return Promise.reject(error);
//     }

//     if (
//       (error.response.data.code === 'token_not_valid'
//       || error.response.data.detail === 'Authentication credentials were not provided.')
//       && !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       try {
//         await store.dispatch(refreshToken());
//         if (originalRequest.url === 'auth/jwt/verify') {
//           originalRequest.data = { token: getAccessToken() }
//         }
//         originalRequest.headers.Authorization = `Token ${getAccessToken()}`
//         return axiosInstance(originalRequest);
//       } catch (err) {
//         store.dispatch(logout());
//       }
//     }

//     return Promise.reject(error);
//   }
// );

export const createStore = options =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      auth,
      game,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(api.middleware),
    ...options,
  });

export const store = createStore();
// export const persistor = persistStore(store);
