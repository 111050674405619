import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { PuffLoader } from 'react-spinners';
import { ANIMATION_CLASSES } from '../constants/defaults';
import { Flex } from '@chakra-ui/react';

const appearAnimationDuration = 600;

const Preloader = () => {
  return (
    <CSSTransition in timeout={appearAnimationDuration} classNames={ANIMATION_CLASSES} mountOnEnter unmountOnExit>
      <Flex
        position="fixed"
        top="0"
        left="0"
        bottom="0"
        zIndex="500"
        width="100vw"
        alignItems="center"
        justifyContent="center"
      >
        <PuffLoader color="white" />
      </Flex>
    </CSSTransition>
  );
};

export default Preloader;
