import { Box, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Fade as Hamburger } from 'hamburger-react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import routes from 'constants/routes';

const Menu = () => {
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    if (isToggled) {
      document.body.classList.add('open-menu');
    } else {
      document.body.classList.remove('open-menu');
    }
    return () => {
      document.body.classList.remove('open-menu');
    };
  }, [isToggled]);
  return (
    <Box width="28px" height="28px" overflow="hidden">
      <Box w="48px" h="48px" ml="-10px" mt="-10px">
        <Hamburger distance="sm" size={18} toggle={setIsToggled} toggled={isToggled} hideOutline={false} />
      </Box>
      <MenuList isDisplayed={isToggled} onClose={() => setIsToggled(false)} />
    </Box>
  );
  // return (
  //   <Flex
  //     position="fixed"
  //     bottom="0"
  //     left="50%"
  //     transform="translateX(-50%)"
  //     zIndex="100"
  //     alignItems="stretch"
  //     bg="#141414"
  //     width="100%"
  //     maxW="400px"
  //     height="70px"
  //   >
  //     <Flex flex="1" alignItems="center" pt="10px" justifyContent="center">
  //       <Box
  //         bg={location.pathname.startsWith(routes.settings) ? "#126340" : undefined}
  //         width="50px"
  //         height="40px"
  //         borderRadius="100px"
  //         to={routes.settings}
  //         as={Link}
  //         display="flex"
  //         alignItems="center"
  //         justifyContent="center"
  //       >
  //         <UserIcon />
  //       </Box>
  //     </Flex>

  //     <Flex flex="1" alignItems="center" pt="10px" justifyContent="center">
  //       <Box
  //         bg={location.pathname.startsWith(routes.game) ? "#126340" : undefined}
  //         width="50px"
  //         height="40px"
  //         borderRadius="100px"
  //         to={routes.game}
  //         as={Link}
  //         display="flex"
  //         alignItems="center"
  //         justifyContent="center"
  //       >
  //         <GameIcon />
  //       </Box>
  //     </Flex>

  //     <Flex flex="1" alignItems="center" pt="10px" justifyContent="center">
  //       <Box
  //         bg={location.pathname.startsWith(routes.prizes) ? "#126340" : undefined}
  //         width="50px"
  //         height="40px"
  //         borderRadius="100px"
  //         to={routes.prizes}
  //         as={Link}
  //         display="flex"
  //         alignItems="center"
  //         justifyContent="center"
  //       >
  //         <PrizesIcon />
  //       </Box>
  //     </Flex>

  //   </Flex>
  // )
};

export default Menu;

const MenuList = ({ isDisplayed, onClose }) => {
  const links = [
    {
      title: 'Home',
      href: routes.home,
    },
    {
      title: 'Game lobby',
      href: routes.game,
    },
    {
      title: 'Prizes',
      href: routes.prizes,
    },
    {
      title: 'Profile',
      href: routes.settings,
    },
  ];
  return createPortal(
    <Box
      bg="black"
      pos="fixed"
      maxW="400px"
      w="100%"
      top="0"
      left="50%"
      transform="translateX(-50%)"
      bottom="0"
      zIndex="100"
      pt="73px"
      px="15px"
      display={isDisplayed ? 'block' : 'none'}
    >
      {links.map(l => (
        <Flex key={l.title} h="50px" align="center" color="white" fontSize="24px" fontWeight="800">
          <Link to={l.href} onClick={onClose}>
            {l.title}
          </Link>
        </Flex>
      ))}
    </Box>,
    document.body,
  );
};
