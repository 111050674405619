import { Box, Button, Flex, Portal } from '@chakra-ui/react';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ChatIcon } from 'icons/chat-icon.svg';
import { ReactComponent as ChatswordIcon } from 'icons/chat-sword.svg';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as ChatSubmitIcon } from 'icons/chat-submit.svg';
import { useSearchParams } from 'react-router-dom';

export default function Chat() {
  const [expanded, setExpanded] = useState(false);
  const [searchParams] = useSearchParams();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const deferredPayload = useRef([]);

  const socketRef = useRef(null);
  const messageContainerRef = useRef();

  const scrollToBottom = useCallback(() => {
    const el = messageContainerRef.current;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (!expanded) return;
    if (messageContainerRef.current) {
      const el = messageContainerRef.current;
      const shouldScroll = el.scrollHeight - el.scrollTop - el.clientHeight < 34;
      if (shouldScroll) {
        setTimeout(scrollToBottom, 100);
      }
    }
  }, [expanded, messages, scrollToBottom]);

  useLayoutEffect(() => {
    if (expanded) {
      scrollToBottom();
    }
  }, [expanded, scrollToBottom]);

  const visibleMessages = useMemo(() => {
    const sorted = [...messages].sort((a, b) => a.id - b.id);
    return expanded ? sorted : sorted.slice(-3);
  }, [expanded, messages]);

  useEffect(() => {
    let timer;
    let terminated = false;

    const initializeWebSocket = () => {
      timer = null;
      if (terminated) {
        socketRef.current?.close();
        return;
      }

      const wsBaseUrl = `${process.env.REACT_APP_WEBSOCKET_ENDPOINT || 'wss://api.solosurvivor.co/ws/'}chat/`;
      socketRef.current = new WebSocket(wsBaseUrl);
      socketRef.current.onmessage = event => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'init') {
            setMessages(data.messages);
          } else if (data.type === 'add') {
            setMessages(messages => [...messages, data.message]);
          }
        } catch (error) {
          console.error(error);
        }
      };
      socketRef.current.onopen = () => {
        if (deferredPayload.current.length > 0) {
          deferredPayload.current.forEach(message => {
            socketRef.current.send(message);
          });

          deferredPayload.current = [];
        }
      };
      socketRef.current.onclose = () => {
        timer = setTimeout(initializeWebSocket, 2000);
        socketRef.current = null;
      };
    };

    initializeWebSocket();

    return () => {
      terminated = true;
      socketRef.current?.close();
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = expanded ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [expanded]);

  const onSubmit = () => {
    if (message.trim().length > 0) {
      if (socketRef.current?.readyState === 1) {
        try {
          socketRef.current.send(message);
        } catch (err) {
          console.error(err);
          return;
        }
      } else {
        deferredPayload.current.push(message);
      }
      scrollToBottom();
      setMessage('');
    }
  };

  return (
    <Portal>
      <Box
        pos="fixed"
        maxW="400px"
        width="100%"
        left="50%"
        transform="translateX(-50%)"
        bottom="0"
        height={expanded ? '100vh' : 'auto'}
      >
        <Box
          position={expanded ? 'absolute' : 'relative'}
          top={expanded ? 'calc(50vh - 130px)' : '0'}
          bottom="0"
          left="0"
          right="0"
          bg="#242424"
          boxShadow="0px -50px 50px 0px #24242480"
          pb={searchParams.get('layout') === 'ios_native' ? `${searchParams.get('bottom_offset')}px` : '0'}
        >
          <Flex flexDir="column" height="100%">
            <Box pos="relative" flexBasis="100%">
              <Box
                maxH="100%"
                position={expanded ? 'absolute' : 'relative'}
                bottom="0"
                left="0"
                right="0"
                overflowY="auto"
                pt={expanded ? '0' : '5px'}
                ref={messageContainerRef}
              >
                {visibleMessages.map((message, index) => (
                  <Message expanded={expanded} key={index} {...message} />
                ))}
              </Box>
            </Box>

            <Flex flexGrow="0" flexShrink="0" px="15px" pt="10px" pb="10px" gap="8px">
              <TextareaAutosize
                maxRows="3"
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    onSubmit();
                  }
                }}
                style={{
                  border: '1px solid #FFFFFF1A',
                  padding: '11px 16px',
                  background: '#FFFFFF1A',
                  borderRadius: '10px',
                  fontSize: '13px',
                  lineHeight: '15.6px',
                  fontWeight: '600',
                  color: '#FFFFFF',
                  flexBasis: '100%',
                  resize: 'none',
                }}
                placeholder="Type something…"
              />
              <Button
                height="40px"
                width="40px"
                borderRadius="10px"
                flexBasis="40px"
                flexShrink="0"
                flexGrow="0"
                onClick={onSubmit}
              >
                <ChatSubmitIcon />
              </Button>
            </Flex>
          </Flex>
          <Box
            position="absolute"
            right="15px"
            top="-16px"
            cursor="pointer"
            transformOrigin="center center"
            transform={expanded ? 'scaleY(-1)' : 'scaleY(1)'}
            onClick={() => setExpanded(!expanded)}
          >
            <ChatIcon />
          </Box>
        </Box>
      </Box>
    </Portal>
  );
}

const Message = ({ expanded, color, username, message, is_in_contest }) => (
  <Box
    fontSize="13px"
    lineHeight="15.6px"
    px="15px"
    pt="8px"
    pb="8px"
    height={expanded ? 'auto' : '32px'}
    minH="32px"
    position="relative"
    alignItems="center"
    display={expanded ? 'block' : 'flex'}
  >
    {is_in_contest && (
      <Box as="span" mr="5px" display="inline-block" verticalAlign="-2px">
        <ChatswordIcon />
      </Box>
    )}
    <Box as="span" color={color} fontWeight="800" mr="4px">
      {username}:
    </Box>
    <Box
      as="span"
      fontWeight="600"
      textOverflow={expanded ? 'unset' : 'ellipsis'}
      whiteSpace={expanded ? 'normal' : 'nowrap'}
      display={expanded ? 'inline' : 'inline-block'}
      overflow="hidden"
      width={expanded ? 'auto' : 'calc(100% - 42px)'}
      py="2px"
    >
      {message}
    </Box>
  </Box>
);
