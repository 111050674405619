import { Box, Button, Center, Text } from '@chakra-ui/react';
import Preloader from 'components/Preloader';
import Prize from 'components/prize/Prize';
import { useGameSettingsQuery } from 'store/services/api';

const ResultPage = () => {
  const { data: settings, isLoading: isSettingsLoading } = useGameSettingsQuery();
  if (isSettingsLoading) return <Preloader />;
  return (
    <Box>
      <Text size="title">
        YOU’RE
        <br />
        THE CHAMP!
      </Text>
      {settings.schedule.length > 0 && (
        <Box mt="30px">
          <Prize mininal prize={settings.schedule[0]} />
        </Box>
      )}
      <Center mt="30px">
        <Button>Claim your prize</Button>
      </Center>
    </Box>
  );
};

export default ResultPage;
