import { Box, Button, Center, FormLabel, Text } from '@chakra-ui/react';
import Input from 'components/form/Input';
import routes from 'constants/routes';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { selectUser } from 'store/slices/authSlice';
import { required } from 'utils/validation';
import { useChangePasswordMutation } from 'store/services/users';
import Error from 'components/form/Error';

const ChangePasswordPage = () => {
  const user = useSelector(selectUser);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [sent, setSent] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [touched, setTouched] = useState({});
  const [changePasswordMutation] = useChangePasswordMutation();
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to={routes.signin} />;
  }

  const onSubmit = async e => {
    e.preventDefault();
    setErrors({});
    setIsSubmiting(true);
    try {
      const body = { old_password: oldPassword, new_password: password };
      await changePasswordMutation(body).unwrap();
      setSent(true);
      navigate(routes.settings, { replace: true });
    } catch (error) {
      if (error.status === 400) {
        const errors = Object.keys(error.data).reduce((acc, key) => {
          acc[key] = Array.isArray(error.data) ? error.data[key][0] : error.data[key];
          return acc;
        }, {});
        setErrors(errors);
      }
    }
    setIsSubmiting(false);
  };

  if (sent) {
    return (
      <Box mt="150px">
        <Text size="title1">Your password was successfully updated</Text>
        <Text color="var(--chakra-colors-white80)" size="body" textAlign="center" mt="20px" lineHeight="22px">
          You can now use your new password to log in to your account
        </Text>
        <Center mt="30px">
          <Button as={Link} to={routes.signin}>
            Log in
          </Button>
        </Center>
      </Box>
    );
  }

  return (
    <Box>
      <Text size="title">Change password</Text>

      <Box mt="30px" as="form" onSubmit={onSubmit}>
        <Box>
          <FormLabel>Old password</FormLabel>
          <Input
            name="old-password"
            type="password"
            value={oldPassword}
            onChange={e => {
              setTouched({ ...touched, 'old-password': true });
              setOldPassword(e.target.value.trim());
            }}
          />

          {touched['password'] && required(oldPassword) && <Error mt="10px">It’s required</Error>}
        </Box>
        <Box mt="20px">
          <FormLabel>New password</FormLabel>
          <Input
            name="password"
            type="password"
            value={password}
            onChange={e => {
              setTouched({ ...touched, password: true });
              setPassword(e.target.value.trim());
            }}
          />

          {touched.password && required(password) && <Error mt="10px">It’s required</Error>}
        </Box>
        <Box mt="20px">
          <FormLabel>Repeat new password</FormLabel>
          <Input
            name="repeat-password"
            type="password"
            value={repeatPassword}
            onChange={e => {
              setTouched({ ...touched, repeatPassword: true });
              setRepeatPassword(e.target.value.trim());
            }}
          />

          {touched.repeatPassword && password !== repeatPassword && <Error mt="10px">Passwords don’t match</Error>}
        </Box>
        <Center mt="30px">
          <Button
            type="submit"
            isDisabled={isSubmiting || required(oldPassword) || required(password) || password !== repeatPassword}
          >
            Change
          </Button>
          <Button variant="secondary" as={Link} to={routes.settings}>
            Cancel
          </Button>
        </Center>
      </Box>
    </Box>
  );
};

export default ChangePasswordPage;
