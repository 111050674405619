import { Box, Center } from '@chakra-ui/react';
import Prize from 'components/prize/Prize';
import { useEffect } from 'react';

const LoseState = ({ state }) => {
  const { title, prize } = state;
  useEffect(() => {
    document.body.classList.add('lose-state');
    return () => {
      document.body.classList.remove('lose-state');
    };
  }, []);
  return (
    <Box width="100%" mt="30px">
      <Center>
        <Box fontSize="32px" fontWeight="800" maxW="280px" textAlign="center" lineHeight="40px">
          {title}
        </Box>
      </Center>
      <Prize prize={prize} mininal mt="30px" />
    </Box>
  );
};

export default LoseState;
