import { Box } from '@chakra-ui/react';

const SpectatorMode = () => (
  <Box
    height="36px"
    bg="#242424"
    color="white"
    display="flex"
    alignItems="center"
    width="fit-content"
    px="16px"
    fontSize="14px"
    fontWeight="700"
    justifyContent="center"
    borderRadius="20px"
    border="1px solid #FFFFFF1A"
  >
    You’re dead 💀
  </Box>
);

export default SpectatorMode;
