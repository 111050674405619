import { Box, Flex, Link } from '@chakra-ui/react';
import routes from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Link as L } from 'react-router-dom';
import { useLogoutMutation } from 'store/services/users';
import { selectUser, setUser } from 'store/slices/authSlice';

const SettingsPage = () => {
  const user = useSelector(selectUser);
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();

  if (!user) return <Navigate to="/" />;

  return (
    <Box>
      <Flex flexDirection="column" alignItems="center">
        <Box fontSize="32px" fontWeight="800">
          Account
        </Box>
        <Box mt="20px" fontSize="18px" fontWeight="700">
          {user.email}
        </Box>
      </Flex>
      <BottomLinks
        logout={async e => {
          e.preventDefault();
          await logout();
          dispatch(setUser(null));
        }}
      />
    </Box>
  );
};

export default SettingsPage;

const BottomLinks = ({ logout }) => (
  <Box mt="40px">
    <BottomLink as={L} to={routes.rules} borderTop="none">
      Game Rules
    </BottomLink>
    <BottomLink href="https://discord.gg/cdRvXyBv" target="_blank">
      Support
    </BottomLink>
    <BottomLink as={L} to={routes.changePassword}>
      Change Password
    </BottomLink>
    <BottomLink onClick={logout}>Log Out</BottomLink>
  </Box>
);

const BottomLink = props => (
  <Link
    h="60px"
    display="flex"
    alignItems="center"
    color="var(--chakra-colors-white80) !important"
    fontSize="16px"
    fontWeight="700"
    w="100%"
    borderTop="1px solid var(--chakra-colors-white10)"
    {...props}
  />
);
