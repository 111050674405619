import { Box, Button, Center, FormLabel, Text } from '@chakra-ui/react';
import Input from 'components/form/Input';
import routes from 'constants/routes';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { selectUser } from 'store/slices/authSlice';
import { match_email } from 'utils/validation';
import { useResetPasswordMutation } from 'store/services/users';
import Error from 'components/form/Error';

const ResetPasswordPage = () => {
  const user = useSelector(selectUser);
  const [params] = useSearchParams();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [sent, setSent] = useState(false);
  const [resetPassword] = useResetPasswordMutation();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [touched, setTouched] = useState({});

  const returnUrl = params && params.get('redirect');

  if (user) {
    return <Navigate to={returnUrl || routes.game} />;
  }

  const onSubmit = async e => {
    e.preventDefault();
    setErrors({});
    setIsSubmiting(true);
    try {
      await resetPassword(email).unwrap();
      setSent(true);
    } catch (error) {
      if (error.status === 400) {
        const errors = Object.keys(error.data).reduce((acc, key) => {
          acc[key] = error.data[key][0];
          return acc;
        }, {});
        setErrors(errors);
      }
    }
    setIsSubmiting(false);
  };

  if (sent) {
    return (
      <Box mt="150px">
        <Text size="title1">We’ve sent a recovery link. Check your email.</Text>
        <Text color="var(--chakra-colors-white80)" size="body" textAlign="center" mt="20px" lineHeight="22px">
          Please check your inbox{' '}
          <Box as="span" color="#FFE500">
            (and spam folder, just in case)
          </Box>{' '}
          for instructions on how to reset your password.
          <br />
          <br />
          If you don’t receive the email within a few minutes, please ensure you've entered the correct email address
          and try again.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text size="title">Reset password</Text>

      <Box mt="30px" as="form" onSubmit={onSubmit}>
        <Box>
          <FormLabel>Email</FormLabel>
          <Input
            name="email"
            type="email"
            placeholder="Enter a valid email address"
            value={email}
            onChange={e => {
              setTouched({ ...touched, email: true });
              setEmail(e.target.value.trim());
            }}
          />

          {touched.email && match_email(email) && <Error mt="10px">Please provide a valid email address</Error>}
        </Box>
        <Center mt="30px">
          <Button type="submit" isDisabled={isSubmiting || match_email(email)}>
            Reset
          </Button>
        </Center>

        <Box mt="40px" color="var(--chakra-colors-white80)" textAlign="center" fontSize="16px" fontWeight="700">
          Remember your password? <Link to={routes.signin}>Log In</Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;
