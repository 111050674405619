import { Box, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

const Prize = ({ prize, mininal }) => {
  if (!prize) {
    return null;
  }
  const date = dayjs(prize.date);
  return (
    <Box>
      {!mininal && (
        <Flex fontSize="14px" fontWeight="600" textTransform="uppercase">
          <Text>{date.format('MM/DD')} Prize</Text>
        </Flex>
      )}
      <Box
        my="10px"
        h="200px"
        bg={`url(${prize.image})`}
        backgroundPosition="center center"
        backgroundSize="cover"
        borderRadius="12px"
        position="relative"
      >
        <Flex
          position="absolute"
          w="80px"
          h="80px"
          borderRadius="50%"
          bg="var(--chakra-colors-green)"
          color="white"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bottom="10px"
          right="10px"
        >
          <Text fontSize="20px" fontWeight="700" color="var(--chakra-colors-white)" lineHeight="1.2">
            ${prize.price}
          </Text>
          <Text fontSize="14px" fontWeight="700" color="var(--chakra-colors-white80)" lineHeight="1">
            value
          </Text>
        </Flex>
      </Box>
      <Text fontSize="18px" fontWeight="700" align={mininal ? 'center' : 'left'}>
        {prize.description}
      </Text>
      {prize.email && (
        <Text color="var(--chakra-colors-yellow)" textTransform="uppercase" fontSize="14px" fontWeight="600" mt="5px">
          claimed by {prize.email}
        </Text>
      )}
    </Box>
  );
};

export default Prize;
