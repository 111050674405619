import { Box, Flex } from '@chakra-ui/react';
import Title from 'components/game/Title';
import SpectatorMode from 'components/game/SpectatorMode';
import WantToContinueButton from './WantToContinueButton';
import { useEffect, useMemo } from 'react';

const colors = {
  success: '#086B45',
  fail: '#373737',
};

const changeThemeColor = color => {
  document
    .querySelectorAll('meta[name=theme-color], meta[name=apple-mobile-web-app-status-bar-style]')
    .forEach(metaTag => metaTag.setAttribute('content', color));
};

const Game = ({ finished, Component, activeQuestion, ...payload }) => {
  const usersInCurrentRoundCount = payload?.state?.usersInCurrentRoundCount;

  useEffect(() => {
    const lose = payload?.state?.state === 'lose' || !payload?.state?.is_in_the_contest;
    changeThemeColor(lose ? colors.fail : colors.success);
  }, [payload?.state?.is_in_the_contest, payload?.state?.state]);

  useEffect(() => {
    return () => {
      changeThemeColor(colors.success);
    };
  }, []);

  const {
    user_lives: userLives,
    is_in_the_contest: inContest,
    can_get_back_to_contest: canBack,
  } = payload?.state ?? {};

  const gameStatus = useMemo(() => {
    if (inContest) {
      return null;
    }
    if (canBack) {
      return <WantToContinueButton userLives={userLives} />;
    }
    return <SpectatorMode />;
  }, [inContest, canBack, userLives]);

  return (
    <Box position="relative" pb="40px">
      <Flex width="100%" height="100%" flexDirection="column" alignItems="center" justifyContent="center">
        {!finished && (
          <Flex width="100%" alignItems="center" justifyContent="center">
            <Box textAlign="center">
              <Flex justifyContent="center">
                <Flex justifyContent="center" height="36px" mb="15px">
                  {gameStatus}
                </Flex>
              </Flex>
              <Title />
              {activeQuestion && (
                <Box mt="10px" fontSize="20px" lineHeight="24px" fontWeight="800">
                  {activeQuestion.text}
                </Box>
              )}
            </Box>
          </Flex>
        )}
        {Component && <Component {...payload} />}
        {typeof usersInCurrentRoundCount === 'number' && (
          <Box textAlign="center" mt="25px" fontSize="14px" fontWeight="700" color="#FFFFFF66">
            {usersInCurrentRoundCount} answered
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default Game;
